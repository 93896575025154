import { PageTemplate } from './PageTemplate'
import { CustomerJobRoleViewComponent } from '../components/Customer/CustomerJobRoleViewComponent'

const ShowCustomerJobRoleViewPage = () => { 
    return (
        <PageTemplate
            type="customer" headerPage="jobpage" headerElement="jobrole"
            leftPaneComponent={null} mainComponent={ CustomerJobRoleViewComponent()} buttonComponent={null} >
        </PageTemplate>
    );
}
export { ShowCustomerJobRoleViewPage }