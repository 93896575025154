import { createResource, createSignal, splitProps, For, Show, Suspense, JSX, } from "solid-js";
import { useNavigate, useParams } from "@solidjs/router";
import { Avatar, Typography, Button, IconButton, Stack, Card, Alert, Grid, Divider, Box, Switch, FormControlLabel } from "@suid/material";
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { SocialMediasElement, AllocationElement, DurationElement, RatesElement, ContactsElement, CompetenciesElements, TextElement, ValueElement, AddressElement } from '../SmallComponents/SmallTextElements';
import { showWfCustomerJobRolesItems, showWfCustomerToDoIncommingMatchesItems, AvatarWithWfButtons, showWfCustomerToDoCompletedItems, showWfCustomerJobsOpenItems, showWfCustomerToDoProposalItems, showWfCustomerToDoContractItems, showWfCustomerToDoConfirmedInterviewItems, showWfCustomerToDoNotConfirmedInterviewItems, showWfCustomerJobsNotStaffedItems } from '../SmallComponents/SmallAvatarWithWfButtons'

import { EditIcon } from "../icons"
import { CustomerJobMenu } from './CustomerJobMenu'
import { ShowSocialMedia } from '../SmallComponents/SmallSocialMedia';
import { isSmall, isExtraSmall, isLarge, isExtraLarge } from "../../../utils/sizeUtil"
import { getText, GetTextUI } from '../SmallComponents/Translate'
import { formatDateYearMonthByCountry } from "../../../utils/datetimeflag"

import { CustomerCVSOMEDataElementsType, CustomerJobContactsDataElementsType, CustomerJobRoleFetchType, CustomerJobRoleDataElementsType, CustomerJobFetchType } from "../../../types/customerJobDataTypes"
import { CandidateCompetenciesFetchType, CandidateCVCompetenceDataElementsType } from "../../../types/candidateCVDataTypes"

import { formatDateByCountry } from "../../../utils/datetimeflag"

const apiUrl = import.meta.env.VITE_APP_API_URL;

const customerJobsMatchesNotStartedWFFilter = ["WFA0"]

const fetchJob = async (jobRoleId: string | null): Promise<CustomerJobRoleFetchType> => {
    const response = await fetch(`${apiUrl}/job/customerjobrole/${jobRoleId}`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('Jobrole response was not ok.');
    }
    const data = await response.json();
    return data;
}

type CardProps = {
    iconName: string
    header: string;
    avatarList?: CustomerJobRoleDataElementsType; //any[];
    filterValues: string[];
    showWfItemsFunction: any;
    defaultOpen: boolean;
    dummyText?: string;
};

const CustomerJobRoleViewComponent = () => {

    const params = useParams();
    const fetchId = params.id ? params.id : "0";

    const [job] = createResource<CustomerJobRoleFetchType>(() => fetchJob(fetchId));

    const txtJobDetails = getText("jobpage", "details")

    const navigate = useNavigate();
    const handleNavigateRoute = (id: string) => {
        if (id) navigate(id);
    }

    const RenderCard = (props: CardProps) => {
        const [local] = splitProps(props, ['dummyText'])

        //<For each={props?.avatarList?.filter(filterItem => (filterItem.wfStatus === props.filterValue && (jobRoleFilterId() ? filterItem.jobRoleId === jobRoleFilterId() : true)))}>

        return (
            <>
                <Show when={props?.avatarList?.length}>

                    <CardWithMinimize iconName={props.iconName} header={props.header + " (" + props?.avatarList?.length + ")"} type="stack" defaultOpen={props.defaultOpen}>

                        <For each={props?.avatarList}>
                            {(item) => (
                                <AvatarWithWfButtons
                                    avatarSize="medium"
                                    showWfItems={props.showWfItemsFunction}
                                    candidateId={item.candidateId}
                                    jobRoleId={item.jobRoleId}
                                    jobAssignmentId={item.jobAssignmentId}
                                    avatarName={item.firstName ? item.firstName + " " + item.lastName : item.jobRoleName}
                                    jobName={item.jobName}
                                    locationCountry={item.jobCountry}
                                    locationCity={item.jobCity}
                                    jobStartDate={formatDateByCountry(item.jobStartDate)}
                                    jobEndDate={formatDateByCountry(item.jobEndDate)}
                                    workOnsite={item.workOnsite}
                                    workRemote={item.workRemote}
                                    onSiteText={item.onSiteText}
                                    currency={item.currency}
                                    rateFlat={item.maxFlatRate}
                                    rateOnsite={item.maxOnSiteRate}
                                    rateRemote={item.maxRemoteRate}
                                    wfStatus={item.wfStatus}
                                    role={item.jobRoleName}
                                    src={item.src}
                                />
                            )}
                        </For>
                    </CardWithMinimize>

                </Show>
            </>
        );
    };

    return (
        <>
            <Card sx={{ margin: 1 }}>

                {/* First row of the job section show the local menubar, tags and other action buttons */}
                <CustomerJobMenu showClose={true} showActivities={false} />

                <Suspense fallback={
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        <GetTextUI formName={"default"} label={"loading"} />.
                    </Alert>
                }>
                    <For each={job()?.jobRole}>
                        {(item) => (
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Stack spacing={2} margin={2} direction={"column"}>
                                            <Stack spacing={2} direction={isExtraSmall() || isSmall() ? "column" : "row"}>
                                                <Avatar sx={{ margin: 2, display: 'flex', width: 100, height: 100 }} variant="rounded" src={item.src} />

                                                <Stack margin={2} spacing={0.5}>
                                                    <Typography variant="h4">{item.companyName}</Typography>
                                                    <Typography variant="h5">{item.jobName}</Typography>
                                                    <Stack direction="row">
                                                        <Typography variant="h5">{item.jobRoleName}</Typography>
                                                        <IconButton size="small" onClick={() => handleNavigateRoute("/customerjobroleedit")}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Stack>
                                                </Stack>
                                            </Stack>

                                            <Card    >
                                                <Stack direction="column" alignItems="left" sx={{ px: 2, py: 1, display: 'flex' }} >
                                                    <Typography variant="overline" gutterBottom><GetTextUI formName={"smallcomponent"} label={"status"} />  </Typography>
                                                    <Show when={Number(item.jobRoleNumberAssignments) > 0}
                                                        fallback={
                                                            <>
                                                                <Typography variant="h6" sx={{ px: 1, display: 'block' }}>{item.jobRoleNumberAssignments} <GetTextUI formName={"jobpage"} label={"jobnotcandidates"} />   </Typography>
                                                                <Button onClick={(event) => handleNavigateRoute("/customermatching")}> <GetTextUI formName={"jobpage"} label={"smartsearch"} /></Button>
                                                            </>
                                                        }
                                                    >
                                                        <Stack direction="column" alignItems="left" sx={{ px: 2, py: 1, display: 'flex' }} >
                                                            <Typography variant="h6" sx={{ px: 1, display: 'block' }}>{item.jobRoleNumberAssignments} <GetTextUI formName={"jobpage"} label={"candidatesidentifed"} /> </Typography>
                                                        </Stack >
                                                    </Show>
                                                </Stack >

                                            </Card>

                                        </Stack>


                                        {/* Show a card for each group */}
                                        <Show when={job()?.jobRole !== undefined}>
                                            <RenderCard dummyText="d" iconName="matching" filterValues={customerJobsMatchesNotStartedWFFilter} header={"Candidates"} defaultOpen={isExtraSmall() ? false : true} avatarList={job()?.jobAssignments} showWfItemsFunction={showWfCustomerJobRolesItems} />
                                        </Show>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <CardWithMinimize level={1} header={txtJobDetails() as string} type="stack" defaultOpen={isExtraSmall() ? false : true}>

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <Stack spacing={1} margin={2}>

                                                    <TextElement page="jobpage" label="jobdescription" data={item.jobDescription} />

                                                    <SocialMediasElement page="jobpage" label="social" SOMEs={job()?.jobSOMEs as CustomerCVSOMEDataElementsType} />

                                                  

                                                    <ContactsElement page="jobpage" label="contacts" contacts={job()?.contacts as CustomerJobContactsDataElementsType} />

                                                    {/* Project location */}
                                                    <AddressElement page="contact" label="address" data={""} country={item?.jobCountry} postalCode={item?.jobPostalCode} city={item?.jobCity} street={item?.jobStreet} />
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                                                <Stack direction="column" spacing={1}>
                                                    <TextElement page="jobpage" label="jobrole" data={item.jobRoleName} />
                                                    <TextElement page="jobpage" label="jobroledescription" data={item.jobRoleDescription} />
                                                    <TextElement skip={true} page="jobpage" label="jobroleexpectation" data={item.jobRoleExpectation} />
                                                    <TextElement skip={true} page="jobpage" label="jobroleadditionalinformation" data={item.jobRoleAdditionalInformation} />
                                                    <br />

                                                    <DurationElement page="default" label="duration" jobStartDate={item.jobStartDate} jobEndDate={item.jobEndDate} />


                                                    <br />
                                                    <AllocationElement units="hours" unitsPer="week" workRemote={item.workRemote} workOnsite={item.workOnsite} />


                                                    <RatesElement page="default" label="budget" flatRate={item.maxFlatRate} onSiteRate={item.maxOnSiteRate} remoteRate={item.maxRemoteRate} currency={item.currency} />

                                                </Stack>
                                            </Grid>
                                        </CardWithMinimize>
                                    </Grid>
                                </Grid>

                                <CompetenciesElements showAddButton={false} page={"jobpage"} label={"customercompetenciesexplainedrole"} options={job() as CandidateCompetenciesFetchType} />

                            </>
                        )}
                    </For>

                    <Show when={!job()?.jobRole || (job()?.jobRole?.length === 0)}>
                        <Alert sx={{ px: 1, py: 1 }} severity="info">
                            <GetTextUI formName={"jobpage"} label={"jobrolenotfound"} />
                        </Alert>
                    </Show>

                </Suspense>
            </Card>
            <br />
            <br />
        </>
    );
}

export { CustomerJobRoleViewComponent }  